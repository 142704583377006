// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pest-control-pricing-js": () => import("./../../../src/pages/pest-control-pricing.js" /* webpackChunkName: "component---src-pages-pest-control-pricing-js" */),
  "component---src-templates-blog-template-js": () => import("./../../../src/templates/BlogTemplate.js" /* webpackChunkName: "component---src-templates-blog-template-js" */),
  "component---src-templates-info-js": () => import("./../../../src/templates/Info.js" /* webpackChunkName: "component---src-templates-info-js" */),
  "component---src-templates-region-js": () => import("./../../../src/templates/Region.js" /* webpackChunkName: "component---src-templates-region-js" */),
  "component---src-templates-selling-js": () => import("./../../../src/templates/Selling.js" /* webpackChunkName: "component---src-templates-selling-js" */),
  "component---src-templates-transport-js": () => import("./../../../src/templates/Transport.js" /* webpackChunkName: "component---src-templates-transport-js" */)
}

